.container {
    height: calc(95vh - 60px);
    width: 80%;
    align-items: center;
    justify-content: center;
    margin-left: 10%;
    margin-right: 10%;
    background-color: rgba(254, 161, 80, 0.2);
}

.container1 {
    display: flex;
    align-items: center;
}

.image-container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-container img {
    max-width: 100%;
    max-height: 100%;
}

.text-container {
    width: 45%;
    align-items: center;
    justify-items: start;
    margin-right: 5%;
}

.highlight {
    color: #FF8C28;
    font-weight: bold;
}

.textcontainer {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: start;
    margin-left: 5%;
}

.textcontainer input {
    margin-bottom: 6%;
}

.textcontainer textarea {
    margin-top: -2%;
    width: 85%;
    padding: 15px;
    border-radius: 20px;
    border: 0.5px solid black; 
    font-size: 16px;
    &:focus {
        border: 0.5px solid orange; 
    }
}



.Buttongetc {
    margin-top: 4%;
    width: 90%;
    height: 40px;
    font-size: 16px;
    display: inline-block;
    border-radius: 2em;
    text-decoration: none;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
    display: flex;
    border: none;
    justify-content: center;
    align-items: center;
    padding-left: 4%;
    padding-right: 4%;
    transition: 0.5s;
    background: linear-gradient(45deg, var(--purple), var(--yellow));
}

.Buttongetc:hover {
    filter: drop-shadow(0 0 4px #FF8C28);
}


@media(max-width:800px) {
    .container {
        height: auto;
    }

    .container1 {
        display: flex;
        flex-direction: column;
    }

    .text-container {
        width: 90%;
        align-items: center;
        justify-items: start;
        margin-right: 5%;
        margin-left: 5%;
    }

    .image-container {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .textcontainer {
        margin-top: 10%;
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: start;
        margin-left: 5%;
        margin-right: 5%;
    }
    
    .textcontainer input {
        margin-bottom: 6%;
    }
}