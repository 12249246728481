.place-order {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  margin-top: 1%;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 5%;
}

.place-order-left {
  width: 100%;
  max-width: min(30%, 500px);
  margin-top: 2%;
}

.place-order-left .title {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
}

.place-order-left input {
  margin-bottom: 15px;
  width: 93%;
  padding: 10px;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  outline-color: #FEA150;
}

.helper-text {
  margin-bottom: 15px;
}

.place-order-left .multi-fields {
  display: flex;
  gap: 2%;
}

.place-order-left-button {
  border: none;
  color: white;
  background-color: #FEA150;
  width: 100%;
  padding: 12px 0px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.place-order-right {
  width: 100%;
  max-width: min(40%, 500px);
  margin-top: 3%;
}

.place-order .cart-total button {
  margin-top: 10px;
}

.place-order-page {
  position: relative;
}

.place-order-page .custom-datepicker {
  width: 100%;
}


.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-animation {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #FF8C28;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

.buttoneircheck {
  border: none;
  color: black;
  background-color: rgba(254, 161, 80, 0.6);
  width: 100%;
  padding: 12px 0px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttoneirdisable {
  border: none;
  color: black;
  background-color: #fff;
  border: 1px solid rgba(254, 161, 80, 0.6);
  width: 100%;
  padding: 12px 0px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttoneirdisablep {
  border: none;
  color: black;
  background: #f3f3f3;
  width: 100%;
  padding: 12px 0px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


@media (max-width: 800px) {
  .place-order {
    display: flex;
    flex-direction: column;

  }

  .place-order-right {
    width: 100%;
    max-width: min(100%);
    margin-top: -10%;
    margin-bottom: 10%;
  }

  .place-order-left {
    width: 100%;
    max-width: min(100%);
  }
}