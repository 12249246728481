.food-display {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  margin-top: 60px;
  gap: 30px;
  row-gap: 50px;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 5%;
}


.food-display-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.empty-state {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 80vh;
}

.empty-state text {
  font-size: 18px;
}

.empty-state img {
  width: 30%;
  margin-top: 20px;
}


.container {
  background-color: rgba(254, 161, 80, 1.0);
  height: 300px;
  width: 250px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 14px;
  overflow: hidden;
}

.fluid {
  background-color: #FAF9F9;
  min-height: 200%;
  min-width: 230%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 35%;
  position: absolute;
  z-index: 1;
  animation: spin 3s linear infinite;
  opacity: 0;
}

.container:hover .fluid {
  opacity: 1;
  /* Show the fluid element on hover */
}

@keyframes spin {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(-900px) rotate(360deg);
  }
}

@media(max-width:800px) {
  .empty-state {
    height: 80vh;
  }

  .empty-state img {
    width: 400px;
  }

  .empty-state text {
    font-size: 14px;
  }
}

@media(max-width:600px) {
  .empty-state {
    height: 60vh;
  }
  .empty-state img {
    width: 230px;
  }
  .empty-state text {
    font-size: 12px;
  }
}