/* General styles */
.cart {
    margin-top: 30px;
    margin-bottom: 100px;
    margin-left: 10%;
    margin-right: 10%;
}

.buttoneirdisableee {
    border: none;
    color: black;
    border: 1px solid rgba(254, 161, 80, 0.6);
    padding: 12px 0px;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cart-items-title {
    display: grid;
    grid-template-columns: 1.5fr 1.5fr 1fr 1fr 1fr 0.8fr 0.8fr;
    align-items: center;
    margin-bottom: -30px;
    color: grey;
    font-size: max(1vw, 12px);
}

.cart-items-item {
    margin: 10px 0px;
    color: black;
}

.cart-items-item img {
    width: 50px;
}

.cart-bottom {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    gap: max(12vw, 20px);
}

.cart-total {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.cart-total h2 {
    margin-bottom: -20px;
}

.cart-total-details {
    display: flex;
    justify-content: space-between;
    color: #555;
}

.cart-total-details-total {
    display: flex;
    justify-content: space-between;
    color: #555;
    margin-top: 20px;
}

.cart-total hr {
    margin: -10px 0px;
}

.cart-total button {
    border: none;
    color: white;
    background-color: #FEA150;
    width: max(15vw, 200px);
    padding: 12px 0px;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.5s;
    background: linear-gradient(45deg, var(--purple), var(--yellow));
}

.cart-total button:hover {
    filter: drop-shadow(0 0 4px #FF8C28);
}

.cart-promocode {
    flex: 1;
}

.cart-promocode p {
    color: #555;
}

.cart-promocode-input {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #eaeaea;
    border-radius: 4px;

}

.cart-promocode-input input {
    background: transparent;
    border: none;
    outline: none;
    padding-left: 20px;
    padding-top: 20px;

}

.cart-promocode-input button {
    width: max(10vw, 150px);
    padding: 12px 5px;
    background-color: black;
    border: none;
    color: white;
    border-radius: 4px;

}

@media (max-width: 750px) {
    .cart-bottom {
        flex-direction: column-reverse;
    }

    .cart-promocode {
        justify-content: start;
    }
    .buttoneirdisableee {
        font-size: 12px;
    }
}