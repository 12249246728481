:root {
    --yellow: #FF9BAF;
    --purple: #FEA150;
    --green: #00ff0a;
    --white: #fff;
    --black: #111;
}


.login-popup {
    width: 100%;
    height: 100vh;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-popup-container {
    margin-top: -5%;
    width: 450px;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

}

.login {
    width: 300px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    z-index: 1;
}

h3 {
    font-size: 2em;
    color: var(--black);
    margin-bottom: -1%;
    margin-top: -1%;
}

textarea {
    width: 85%;
    padding: 12px 20px;
    background: transparent;
    border: 0.5px solid rgba(15, 15, 15, 0.5);
    border-radius: 40px;
    outline: none;
    font-size: 1em;
    color: var(--black);
    margin-top: -10px;
}

textarea:focus {
    border-color: #FF8C28;
}

.button {
    margin-top: -10px;
    width: 100%;
    padding: 12px 20px;
    border-radius: 40px;
    border: none;
    font-size: 1.2em;
    color: var(--white);
    cursor: pointer;
    transition: 0.5s;
    background: linear-gradient(45deg, var(--purple), var(--yellow));
}

.button:hover {
    filter: drop-shadow(0 0 4px #FF8C28);
}

i {
    position: absolute;
    border: 1px solid #888;
    inset: 0;
    transition: 0.5s;
}

.b1 {
    border-radius: 38% 62% 63% 37% / 41% 44% 56% 59%;
    animation: clockwise 6s linear infinite;
}

.b3 {
    border-radius: 41% 44% 56% 59%/38% 62% 63% 37%;
    animation: counterclockwise 10s linear infinite;
}

@keyframes clockwise {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes counterclockwise {
    0% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.login-popup-container:hover .b1 {
    border: 1px solid #FF8C28;
}

.login-popup-container:hover .b3 {
    border: 1px solid #ccc;
}

@media(max-width:800px) {
    .login-popup-container {
        width: 400px;
        height: 400px;
    }

    .login {
        width: 250px;
    }
}

@media(max-width:600px) {
    .login-popup {
        height: 80vh;
    }

    .b1 {
        display: none;
    }

    .b3 {
        display: none;
    }
}