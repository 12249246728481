.navbar {
    align-items: center;
    background: #ffffff;
    box-shadow: 0 4px 2px -2px rgba(253, 251, 251, 0);
    height: 63px;
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 999;
}

.navbar.scrolled {
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.15);

}

.Logotext {
    font-size: 24px;
    font-weight: semibold;
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    color: #000000;
}

.Logotext:hover {
    color: #FEA150;
}

.MenuItem {
    font-size: 17px;
    cursor: pointer;
    margin-right: 18px;
    align-items: center;
    display: flex;
    color: #000000;
}

.MenuItem:hover {
    color: #FEA150;
}

.MenuItems {
    font-size: 17px;
    cursor: pointer;
    margin-right: 18px;
    display: flex;
    flex-direction: column;
    color: #000000;
    margin-top: 3%;
}

.MenuItems:hover {
    color: #72838C;
}

.Buttonget {
    font-size: 15px;
    display: inline-block;
    padding: 0.1em 0.7em;
    border-radius: 2em;
    border: 0.1em solid #FEA150;
    text-decoration: none;
    color: #FFFFFF;
    background-color: #FEA150;
    text-align: center;
    position: relative;
    text-align: center;
    cursor: pointer;
    display: flex;
    margin-right: 18px;
}

.ProfileIconContainer {
    margin-right: 20px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
}


.Buttonget:hover {
    background-color: #FF8C28;
}

.Buttonget:active {
    background-color: #EBEDEF;
    color: #000000;
    border: 0.1em solid #EBEDEF;
}

.Buttongetc {
    font-size: 12px;
    display: inline-block;
    border-radius: 2em;
    border: 0.1em solid #FEA150;
    text-decoration: none;
    color: #FFFFFF;
    background-color: #FEA150;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 4%;
    padding-right: 4%;
    transition: 0.5s;
    background: linear-gradient(45deg, var(--purple), var(--yellow));
}

.Buttongetc:hover {
    background-color: #FF8C28;
}


.Buttongetw {
    font-size: 15px;
    display: inline-block;
    border-radius: 2em;
    border: 0.1em solid #000000;
    text-decoration: none;
    color: #FEA150;
    background-color: #000000;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-left: 1%;
    padding-right: 1%; */
}
.imagecontainer{
    height: 45vh;
}
.search{
    display: flex;
    align-content: center;
}

.search button {
    border: none;
    height: 2%;
    color: white;
    background-color: #FEA150;
    width: max(15vw, 200px);
    padding: 12px 0px;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.5s;
    background: linear-gradient(45deg, var(--purple), var(--yellow));
}

.search button:hover {
    filter: drop-shadow(0 0 4px #FF8C28);
}

.Buttongetw:hover {
    background-color: #FF8C28;
    color: #ffffff;
    border: 0.1em solid #FF8C28;
}

.Buttongetw:active {
    background-color: #EBEDEF;
    color: #000000;
    border: 0.1em solid #EBEDEF;
}

.Buttonlogin {
    display: inline-block;
    padding: 0.1em 0.7em;
    border-radius: 2em;
    border: 0.1em solid #ffffff;
    text-decoration: none;
    color: #000000;
    background-color: transparent;
    text-align: center;
    cursor: pointer;
    font-weight: semibold;
    transition: all 0.2s;
    display: flex;
    margin-right: 18px;
    align-items: center;
}

.Buttonlogin:hover {
    background-color: #FEA150;
    color: #ffffff;
}

.Buttonlogin:active {
    background-color: #EBEDEF;
    color: #000000;
}

.Buttonlogins {
    display: inline-block;
    padding: 0.1em 0.7em;
    border-radius: 2em;
    border: 0.1em solid #A1A1A1;
    text-decoration: none;
    color: #000000;
    background-color: transparent;
    text-align: center;
    cursor: pointer;
    font-weight: semibold;
    transition: all 0.2s;
    display: flex;
    margin-right: 18px;
    justify-content: center;
}

.Buttonlogins:hover {
    background-color: #C3CACE;
}

.Buttonlogins:active {
    background-color: #EBEDEF;
}


.Buttongeto {
    font-size: 15px;
    display: inline-block;
    padding: 0.1em 0.7em;
    border-radius: 2em;
    border: 0.1em solid #FEA150;
    text-decoration: none;
    color: #FFFFFF;
    background-color: #FEA150;
    text-align: center;
    position: relative;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    display: flex;
    margin-top: 4%;
    margin-right: 18px;
}

.Buttonget:hover {
    background-color: #FF8C28;
}

.Buttonget:active {
    background-color: #EBEDEF;
    color: #000000;
    border: 0.1em solid #EBEDEF;
}


.Buttonlogino {
    display: inline-block;
    padding: 0.1em 0.7em;
    border-radius: 2em;
    border: 0.1em solid #ffffff;
    text-decoration: none;
    color: #000000;
    background-color: transparent;
    text-align: center;
    cursor: pointer;
    font-weight: semibold;
    transition: all 0.2s;
    display: flex;
    margin-right: 18px;
    align-items: center;
}

.Buttonlogin:hover {
    background-color: #FEA150;
    color: #ffffff;
}

.Buttonlogin:active {
    background-color: #EBEDEF;
    color: #000000;
}

.image{
    height: 200px;
    margin-right: 10%;
}
.popularfood{
    display: flex;
    margin-top: 5%;
}




@media screen and (max-width: 992px) {
    .MenuItem {
        display: none;
    }

    .Buttonget {
        display: none;

    }

    .ProfileIconContainer {
        display: none;
    }

    .Buttonlogin {
        display: none;
    }

    .Buttongets {
        display: none;
    }

    .Buttonlogins {
        margin-left: -15%;
        height: 5%;
        margin-top: 15%;
        font-size: 12px;
    }



}

@media screen and (min-width: 913px) {
    .Buttongets {
        display: none;
    }

    .Buttonlogins {
        display: none;
    }

    .Buttongeto {
        display: none;
    }

    .Buttonlogino {
        display: none;
    }

}
.hometext{
    margin-left: 10%;
}

@media(max-width:800px){
    .imagecontainer{
      display: none;
    }
    .search{
        flex-direction: column;
    }
    .search button {
        margin-top: 5px;
    }

    
    .hometext{
        margin-left: auto;
        margin-right: auto;
    }
    .Buttongetc{
        margin-top: 10px;
        width: 35%;
        height: 35px;
    }
    .Logotext {
        font-size: 20px;
    }
}

@media (max-width: 500px) {
    .Logotext {
        font-size: 16px;
    }
}
